import React from 'react'
import { myContext } from '../../../provider'
import { SortAscendingIcon, MailIcon } from '@heroicons/react/solid'
import Axios from "axios"

const EmailResetSent = () => {

    return (
        <myContext.Consumer>
            {context => (
                <>
                    <div className="w-1/2 mx-auto h-screen flex flex-col">
                        
                        <div>
                            <div className="block text-md font-medium text-gray-700">
                            An email has been sent to reset your password.
                            </div>

                        </div>
                        
                    </div>
                </>
            )}
        </myContext.Consumer>
    )

}

export default EmailResetSent